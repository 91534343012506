<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Master Penilaian"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Kategori Penilaian" label-cols-md="3">
          <multiselect
            :options="kat"
            v-model="$v.data.masterKategoriPenilaianId.$model"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKategoriPenilaian"
            track-by="id"
            placeholder="-- Pilih Kategori Penilaian --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Penilaian" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('penilaian')"
            type="text"
            v-model="$v.data.penilaian.$model"
            @keydown.enter.prevent="simpan()"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: { Multiselect },
  props: ["kat"],
  name: "modalInput",
  data() {
    return {
      data: {
        penilaian: "",
        masterKategoriPenilaianId: "",
      },
      button: "Simpan",
      busy: false,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      masterKategoriPenilaianId: {
        required,
      },
      penilaian: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.masterKategoriPenilaianId = vm.data.masterKategoriPenilaianId.id;
      axios
        .post(ipBackend + "masterPenilaian/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.data.penilaian = "";
            vm.data.masterKategoriPenilaianId =""
            vm.$v.$reset();
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER PENILAIAN",
              showing: true,
            });
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
